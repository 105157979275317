.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.map:-webkit-full-screen {
  height: 100%;
  margin: 0;
}

.map:-ms-fullscreen {
  height: 100%;
}

.map:fullscreen {
  height: 100%;
}

.map .ol-rotate {
  top: 3em;
}

.ol-zoom {
  top: 1em;
  right: 0.5em;
  left: auto;
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: #3e352e;
  font-weight: bold;
  text-decoration: none;
  font-size: inherit;
  text-align: center;
  height: 30px;
  width: 30px;
  line-height: 0.4em;
  background-color: #f8f5f3;
  border: none;
  border-radius: 2px;
}

.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: #dfdddd;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #3e352e;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution:not(.ol-collapsed) {
  background: rgb(255, 255, 255);
}

.ol-attribution {
  z-index: 5;
  text-align: right;
  bottom: 3.5em;
  right: 0.5em;
  max-width: calc(100% - 1.3em);
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}

.ol-scale-line {
  background-color: transparent;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  right: 0.1em;
  text-shadow: -1px0 black, 0 1px black, 1px0 black, 0 -1px black;
}

.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size:  0.9375rem;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all 0.25s;
  position: absolute;
  bottom: 1em;
  font-weight: 900;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: rgba(255, 255, 255, 0.4);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  box-shadow: 0px 0px 20px -4px rgba (0, 0, 0, 0.34);
}



@media (min-width: 800px) {
  .ol-scale-line-inner {
    right: 0.5em;
  }

  .ol-uncollapsible {
    right: 0.6em !important;
  }

}

@media (max-width: 800px) {
  .ol-attribution {
    bottom: 8.3rem;
    right: 4vw !important;
  }

  .ol-zoom {
    right: 0.5em;
    left: auto;
  }

  .ol-uncollapsible {
    right: 4vw !important;
    bottom: 8.5rem !important
  }

  .ol-scale-line-inner {
    left: 0.5vw;
    bottom: 8rem;
  }
}



.somai-ol-tooltip-helper {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: #fff;
  padding: 8px 8px;
  opacity:0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
  font-weight: 800;
}
.somai-ol-tooltip {
  position: relative;
  background: #fff;
  border-radius: 4px;
  color: #291F17;
  padding: 8px 8px;
  opacity:0.8;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
  font-weight: 800;
}
.somai-ol-tooltip-helper.hidden{
  display:none !important;
}
.ol-tooltip-measure {
  opacity: 0.9;
  background: #fff;
	background-clip: padding-box;
  color: #291F17;
  border: 3px solid #D9D5D2;
	border: 3px solid rgba(212, 213, 210,.8);
  font-weight: 800;
  min-width: 70px;
}
.ol-tooltip-static {
  
  background: #fff;
	background-clip: padding-box;
  color: #291F17;
  border: 3px solid #D9D5D2;
	border: 3px solid rgba(212, 213, 210,.8);
  font-weight: 800;
  /* position: absolute;
  left: 50%;
  bottom: 100%; */
  
}

.ol-tooltip-measure:before, .ol-tooltip-static:before, .ol-tooltip-measure:after,
.ol-tooltip-static:after {
  content: "";
	position: absolute;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	top: 100%;
	left: 65%;
	margin-left: -25px;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  position: absolute;
  border-top: 10px solid #D9D5D2;
	border-top: 10px solid rgba(212, 213, 210,.8);
	margin-top: 3px;
  bottom: -6px;
}

.ol-tooltip-measure:after,
.ol-tooltip-static:after {
  border-top: 8px solid #fff;
	margin-top: -1.5px;
	z-index: 1;
}
.ol-tooltip-static:before {
  border-top-color: #D9D5D2;
}

.somai-attri {
    display:none !important;
}



    