body.somai-confirm-alert-body-element {
  overflow: hidden;
}

.somai-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.somai-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(13, 9, 6, 0.7);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: somai-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: somai-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: somai-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: somai-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.somai-confirm-alert-body {
  font-family: Nunito, Helvetica, sans-serif;
  width: 25rem;
  padding: 2rem 2rem;
  text-align: center;
  background: #ffffff;
  opacity: 0.85;
  border-radius: 0.5rem;
  box-shadow: rgb(217, 213, 210) 0px 1px 0px;
  color: #69625D;
}

.somai-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}



.somai-confirm-alert-body>h3 {
  margin: 0;
  font-size: 16px;
}

.somai-confirm-alert-body>p {
  margin: .6rem 0;
  font-size: medium;
  font-size: 1rem;
}

.somai-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: .2rem;
}

.somai-confirm-alert-button-group>button {
  outline: none;
  background: #D44500;
  border: none;
  border-radius: 1.125rem;
  padding: 0.5rem .7rem;
  color: #eee;
  margin-right: 10px;
  font-size: 0.8rem;
  font-weight: 800;
  cursor: pointer;
}

.somai-confirm-alert-button-group>button:first-child {
  background: transparent;
  border: #D44500 2px solid;
  color: #D44500;

}

@-webkit-keyframes somai-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes somai-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes somai-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes somai-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 400px) {
  .somai-confirm-alert-body {
    max-width: 19rem;
  }

  .somai-confirm-alert-button-group button {
    width: 100%;

  }
}