.somai-react-colorful {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 11.125rem;
  height: 10rem;
  user-select: none;
  cursor: default;
  box-shadow: 1px 1px solid black;
}

.picking-area {
  width: 100%;
  height: 160px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 8px;
}

.picking-area:hover {
  cursor: default;
}
.picking-area .picking-area-overlay1 {
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 3px;

  
}

.picking-area .picking-area-overlay1 .picking-area-overlay2 {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  border-radius: 8px;
}

.preview {
  display: flex;
  flex-direction: row;
}

.color-hue-alpha {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 6px;
}
.color-hue-alpha .hue {
  width: 100%;
  position: relative;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 0 7px;
  background-color: red;

  
}

.color-hue-alpha .hue .hue-area {
  position: relative;
  height: 14px;
  background: -webkit-linear-gradient(left, #ff0000, #ff0080, #ff00ff, #8000ff, #0000ff, #0080ff, #00ffff, #00ff80, #00ff00, #80ff00, #ffff00, #ff8000, #ff0000);
  background: -o-linear-gradient(left, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
  background: -ms-linear-gradient(left, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
  background: -moz-linear-gradient(left, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
  background: linear-gradient(to right, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
}

.color-hue-alpha .alpha {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  height: 14px;
  cursor: pointer;

  
}
.color-hue-alpha .alpha .gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.color-hue-alpha .alpha .alpha-area {
  width: 100%;
  height: 100%;
  /* background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>'); */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8px" height="8px" view-box="0 0 8 8"><g id="◼︎-/-misc-/-textures-/-chess-(24x32)-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.2"><g id="Group-Copy" transform="translate(4.000000, 4.000000) rotate(-270.000000) translate(-4.000000, -4.000000) translate(-0.000000, 0.000000)" fill="#000000"><rect id="Rectangle-8" x="0" y="4" width="4" height="4"></rect><rect id="Rectangle-8-Copy-2" x="4" y="0" width="4" height="4"></rect></g></g></svg>');
  /* background: url("assets/imgs/alpha-background.svg"); */
  background-size: auto;
  background-position: 50% 50%;
  border-radius: 10px;
  padding: 0 7px;

  
}
.color-hue-alpha .alpha .alpha-area .alpha-mask {
  width: 100%;
  height: 100%;
  position: relative;
}

.gradient {
  width: 100%;
  height: 14px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 0 7px;

}

.gradient .gradient-slider-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.picker-pointer {
  position: absolute;
  top: 1px;
  height: 12px;
  width: 12px;
  border: 1px solid #FFFFFF;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.picker-pointer .child-point {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3px;
  width: 3px;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.33s;

  
}

.picker-pointer .child-point.active {
  opacity: 1;
}

.somai-react-colorful__saturation {
  position: relative;
  flex-grow: 1;
  border-color: transparent; 
  /* border-bottom: 12px solid #000; */
  border-radius: 3px;
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)),
    linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.somai-react-colorful__pointer-fill,
.somai-react-colorful__alpha-gradient/* ,.somai-react-colorful__gradient-slider-container */ {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: inherit;
}

/* Improve elements rendering on light backgrounds */
.somai-react-colorful__alpha-gradient,
.somai-react-colorful__gradient-slider-container,
.somai-react-colorful__saturation {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.somai-react-colorful__gradient-slider{
  width: 100%;
            height: 14px;
            position: relative;
            cursor: pointer;
            border-radius: 10px;
            margin-bottom: 8px;
            padding: 0 7px;
}

.somai-react-colorful__gradient-slider .somai-react-colorful__gradient-slider-container {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.somai-react-colorful__hue,
.somai-react-colorful__alpha /* ,.somai-react-colorful__gradient-slider */{
  margin-top: 1rem;
  position: relative;
  border-radius: 8px;
  height: 6px;
}


.somai-react-colorful__hue {
  background: linear-gradient(
    to right,
    #ED1C24 0%,
    #ff0 17%,
    #00FF00 33%,
    #0ff 50%,
    #0000FF 67%,
    #FF00FF 83%,
    #FF0000 100%
  );
 /*  background: linear-gradient(
    to left,
    #f00 100%
    #f0f 83%,
    #00f 67%,
    #0ff 50%,
    #0f0 33%,
    #ff0 17%,
    #f00 0%,
  ); */
}

/* Round bottom corners of the last element: `Hue` for `ColorPicker` or `Alpha` for `AlphaColorPicker` */
.somai-react-colorful__last-control {
  border-radius:8px;
}

.somai-react-colorful__interactive {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  outline: none;
  /* Don't trigger the default scrolling behavior when the event is originating from this element */
  touch-action: none;
}



.somai-react-colorful__pointer{
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  background-color: #D9D5D2;
  border: 2px solid #D9D5D2;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.somai-react-colorful__gradient-slider-pointer {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  background-color: #D9D5D2;
  border: 2px solid #D9D5D2;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.somai-react-colorful__gradient-slider-pointer .child-point {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 4px;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.33s;
}
.somai-react-colorful__gradient-slider-pointer .child-point.active {
  opacity: 1;
}



.somai-react-colorful__interactive:focus .somai-react-colorful__pointer {
  transform: translate(-50%, -50%) scale(1.1);
}

/* Chessboard-like pattern for alpha related elements */
.somai-react-colorful__alpha,.somai-react-colorful__gradient-slider
.somai-react-colorful__alpha-pointer {
  background-color: #fff;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>');
}

/* Display the saturation pointer over the hue one */
.somai-react-colorful__saturation-pointer {
  z-index: 3;
}

/* Display the hue pointer over the alpha one */
.somai-react-colorful__hue-pointer {
  z-index: 2;
}
